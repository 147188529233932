<script setup>
</script>

<template>
    <div class="card rounded shadow-sm p-4">
        <slot />
    </div>
</template>

<style scoped>
    .card:not(.bg-custom) {
        @apply bg-white;
    }
</style>
